import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AdminComponent } from './admin/admin.component';
import { MissingDataDialogComponent } from './admin/missing-data-dialog/missing-data-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { ContractComponent } from './contract/contract.component';
import { CountrysideComponent } from './countryside/countryside.component';
import { FormErrorsComponent } from './debug/form-errors/form-errors.component';
import { EmailBodyDialogComponent } from './email-log/email-body-dialog/email-body.dialog';
import { EmailLogComponent } from './email-log/email-log.component';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { LoginComponent } from './login/login.component';
import { ContactInfoComponent } from './modules/contact-info/contact-info.component';
import { EntryComponent } from './modules/entry/entry.component';
import { OthersComponent } from './modules/others/others.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { PersonalDataComponent } from './modules/personal-data/personal-data.component';
import { StudiesComponent } from './modules/studies/studies.component';
import { UploadsComponent } from './modules/uploads/uploads.component';
import { PaymentFinishComponent } from './payment-finish/payment-finish.component';
import { RegisterComponent } from './register/register.component';
import { RestrictInputDirective } from './shared/directives/restrict-input.directive';
import { HelperIconDirective } from './shared/helper-icon.directive';
import { HelperComponent } from './shared/helper/helper.component';
import { StepperNavigatorComponent } from './shared/stepper-navigator/stepper-navigator.component';
import { UploadConfimationFialogComponent } from './shared/upload-confirmation/upload-confirmation.component';

@NgModule({
   declarations: [
      AppComponent,
      HomeComponent,
      PersonalDataComponent,
      RegisterComponent,
      InfoComponent,
      ContactInfoComponent,
      StudiesComponent,
      OthersComponent,
      EntryComponent,
      PaymentComponent,
      PaymentFinishComponent,
      LoginComponent,
      AdminComponent,
      HelperComponent,
      UploadConfimationFialogComponent,
      HelperIconDirective,
      StepperNavigatorComponent,
      UploadsComponent,
      FormErrorsComponent,
      CountrysideComponent,
      ContractComponent,
      MissingDataDialogComponent,
      EmailLogComponent,
      EmailBodyDialogComponent,
      RestrictInputDirective,
   ],
   imports: [
      // Angular
      HttpClientModule,
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,
      // Angular Material
      MatCardModule,
      MatSliderModule,
      MatStepperModule,
      MatButtonModule,
      MatIconModule,
      MatGridListModule,
      MatInputModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule,
      MatSnackBarModule,
      LayoutModule,
      MatCheckboxModule,
      MatTableModule,
      MatPaginatorModule,
      MatBottomSheetModule,
      MatDialogModule,
      MatProgressSpinnerModule,
      MatChipsModule,
      MatTooltipModule,
      MatRadioModule,
      MatSelectModule,
      // Angular material cdk
      ClipboardModule,
      // Utility
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
         },
         defaultLanguage: 'hu',
      }),
      NgxMaskModule.forRoot(),
      MatPaginatorModule,
   ],
   providers: [
      MatDatepickerModule,
      ...(environment.production
         ? []
         : [{ provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }]),
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
   ],
   bootstrap: [AppComponent],
   entryComponents: [UploadConfimationFialogComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
   return new TranslateHttpLoader(http);
}
