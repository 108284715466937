<div style="margin-left: 10vw; margin-top: 10vh; width: 80vw">
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('wait')"
      [color]="waitColor"
      style="margin-right: 2rem"
      [ngStyle]="{ 'background-color': waitColor }"
   >
      {{ 'admin.wait' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('new')"
      [color]="newColor"
      style="margin-right: 2rem"
      [ngStyle]="{ 'background-color': newColor }"
   >
      {{ 'admin.new' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('rev')"
      [color]="revColor"
      style="margin-right: 2rem"
      [ngStyle]="{ 'background-color': revColor }"
   >
      {{ 'admin.revised' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('need')"
      [color]="needColor"
      style="margin-right: 2rem"
      [ngStyle]="{ 'background-color': needColor }"
   >
      {{ 'admin.need' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('synced')"
      [color]="syncedColor"
      style="margin-right: 2rem"
      [ngStyle]="{ 'background-color': syncedColor }"
   >
      {{ 'admin.synced' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('trash')"
      [color]="trashColor"
      style="margin-right: 2rem"
      [ngStyle]="{ 'background-color': trashColor }"
   >
      {{ 'admin.trash' | translate }}
   </button>
</div>

<form [formGroup]="filterForm">
   <mat-form-field appearance="standard" style="margin-left: 10vw; width: 80vw">
      <mat-label>{{ 'admin.filter' | translate }}</mat-label>
      <input
         matInput
         (keyup)="applyFilter($event)"
         placeholder="{{ 'admin.filter' | translate }}"
         #input
         formControlName="filter"
      />
   </mat-form-field>
</form>

<div class="mat-elevation-z8" style="margin-bottom: 10vh">
   <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      (matSortChange)="announceSortChange($event)"
   >
      <ng-container matColumnDef="uuid" *ngIf="hasResource('person.update-admin')">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
         <td mat-cell *matCellDef="let element">
            <a
               *ngIf="currentFilter !== 'synced'; else simpleID"
               (click)="onNavigate(element.uuid)"
               style="color: blue"
               >{{ element.id ? element.id : '' }}</a
            >
            <ng-template #simpleID> {{ element.id ? element.id : '' }} </ng-template>
         </td>
      </ng-container>
      <ng-container matColumnDef="uuid" *ngIf="!hasResource('person.update-admin')">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
         <td mat-cell *matCellDef="let element">{{ element.id ? element.id : '' }} </td>
      </ng-container>
      <ng-container matColumnDef="lastname">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.lastname' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{ element.name ? element.name?.surname : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="firstname">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.firstname' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.name ? element.name?.firstName : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="dateOfBirth">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.birthDate' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.dateOfBirth ? displayBod(element.dateOfBirth) : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="taxNumber">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.tax' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.taxNumber ? element.taxNumber : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.email' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.email ? element.email : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.create-date' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.createdAt ? convertDateAndTime(element.createdAt) : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="syncDate">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.syncDate' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.syncDate ? convertDateAndTime(element.syncDate) : '-'
         }}</td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
         <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div
               class="example-element-detail"
               [@detailExpand]="
                  expandedElement !== null && expandedElement?.uuid === element.uuid
                     ? 'expanded'
                     : 'collapsed'
               "
               align="center"
            >
               <div class="example-element-description" style="width: 100%">
                  <mat-grid-list cols="4" rowHeight="3rem" style="width: 100%">
                     <mat-grid-tile>
                        <p>
                           {{ 'admin.status' | translate }}:
                           <b>{{ expandedElement?.status ? expandedElement?.status : '' }}</b>
                        </p>
                     </mat-grid-tile>
                     <mat-grid-tile>
                        <p *ngFor="let payment of expandedElement?.payments">
                           <span *ngIf="payment.status === 'FINISHED'">
                              {{ 'admin.trx-status' | translate }}:
                              <b>{{ payment.status }}</b></span
                           >
                        </p>
                     </mat-grid-tile>

                     <mat-grid-tile>
                        <p *ngFor="let payment of expandedElement?.payments">
                           <span *ngIf="payment.status === 'FINISHED'">
                              {{ 'admin.trx' | translate }}:
                              <b>{{ payment.transactionId }}</b></span
                           >
                        </p>
                     </mat-grid-tile>
                     <mat-grid-tile *ngIf="expandedElement?.payments">
                        <div>
                           <p
                              >{{ 'admin.payment-status' | translate }}:
                              <b>{{ expandedElement?.paymentStatus }}</b></p
                           >
                        </div>
                     </mat-grid-tile>
                  </mat-grid-list>
                  <mat-grid-list cols="3" rowHeight="14rem" style="width: 100%">
                     <mat-grid-tile *ngIf="expandedElement?.name">
                        <div *ngIf="expandedElement?.name">
                           <p>{{ 'admin.name' | translate }}:</p>
                           <p *ngIf="expandedElement?.name && expandedElement?.name?.surname"
                              >{{ 'admin.lastname' | translate }}:
                              <b>{{
                                 expandedElement?.name ? expandedElement?.name?.surname : ''
                              }}</b></p
                           >
                           <p *ngIf="expandedElement?.name && expandedElement?.name?.firstName"
                              >{{ 'admin.firstname' | translate }}:
                              <b>{{
                                 expandedElement?.name ? expandedElement?.name?.firstName : ''
                              }}</b></p
                           >
                           <p *ngIf="expandedElement?.name && expandedElement?.name?.middleName"
                              >{{ 'admin.middlename' | translate }}:
                              <b>{{
                                 expandedElement?.name ? expandedElement?.name?.middleName : ''
                              }}</b></p
                           >
                           <p *ngIf="expandedElement?.sex"
                              >{{ 'admin.sex' | translate }}:
                              <b>{{
                                 expandedElement?.sex ? ('' + expandedElement?.sex | translate) : ''
                              }}</b></p
                           >
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile
                        *ngIf="
                           expandedElement?.birthName &&
                           expandedElement?.birthName?.firstName !== ''
                        "
                     >
                        <div *ngIf="expandedElement?.birthName">
                           <p>{{ 'admin.birthname' | translate }}:</p>
                           <p
                              *ngIf="
                                 expandedElement?.birthName && expandedElement?.birthName?.surname
                              "
                              >{{ 'admin.lastname' | translate }}:
                              <b>{{
                                 expandedElement?.birthName
                                    ? expandedElement?.birthName?.surname
                                    : ''
                              }}</b></p
                           >
                           <p
                              *ngIf="
                                 expandedElement?.birthName && expandedElement?.birthName?.firstName
                              "
                              >{{ 'admin.firstname' | translate }}:
                              <b>{{
                                 expandedElement?.birthName
                                    ? expandedElement?.birthName?.firstName
                                    : ''
                              }}</b></p
                           >
                           <p
                              *ngIf="
                                 expandedElement?.birthName &&
                                 expandedElement?.birthName?.middleName
                              "
                              >{{ 'admin.middlename' | translate }}:
                              <b>{{
                                 expandedElement?.birthName
                                    ? expandedElement?.birthName?.middleName
                                    : ''
                              }}</b></p
                           >
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile
                        *ngIf="
                           expandedElement?.motherName &&
                           expandedElement?.motherName?.firstName !== ''
                        "
                     >
                        <div *ngIf="expandedElement?.motherName">
                           <p>{{ 'admin.mother' | translate }}:</p>
                           <p
                              *ngIf="
                                 expandedElement?.motherName && expandedElement?.motherName?.surname
                              "
                              >{{ 'admin.lastname' | translate }}:
                              <b>{{
                                 expandedElement?.motherName
                                    ? expandedElement?.motherName?.surname
                                    : ''
                              }}</b></p
                           >
                           <p
                              *ngIf="
                                 expandedElement?.motherName &&
                                 expandedElement?.motherName?.firstName
                              "
                              >{{ 'admin.firstname' | translate }}:
                              <b>{{
                                 expandedElement?.motherName
                                    ? expandedElement?.motherName?.firstName
                                    : ''
                              }}</b></p
                           >
                           <p
                              *ngIf="
                                 expandedElement?.motherName &&
                                 expandedElement?.motherName?.middleName
                              "
                              >{{ 'admin.middlename' | translate }}:
                              <b>{{
                                 expandedElement?.motherName
                                    ? expandedElement?.motherName?.middleName
                                    : ''
                              }}</b></p
                           >
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile>
                        <div>
                           <p *ngIf="expandedElement?.phone && expandedElement?.phone?.country">
                              {{ 'admin.phone' | translate }}:
                              <a [href]="'tel:' + combinePhoneNumber(expandedElement?.phone)">
                                 <b>{{ combinePhoneNumber(expandedElement?.phone) }}</b>
                              </a>
                           </p>
                           <p>
                              {{ 'admin.email' | translate }}:
                              <a [href]="'mailto:' + expandedElement?.email">
                                 <b>{{ expandedElement?.email }}</b>
                              </a>
                           </p>
                           <p>
                              {{ 'admin.birth-date' | translate }}:
                              <b>{{
                                 expandedElement?.dateOfBirth
                                    ? convertDate('' + expandedElement?.dateOfBirth)
                                    : ''
                              }}</b>
                           </p>
                           <p [class.red-color]="!expandedElement?.placeOfBirthOK">
                              {{ 'admin.birth-place' | translate }}:
                              <b>{{
                                 expandedElement?.placeOfBirth ? expandedElement?.placeOfBirth : ''
                              }}</b>
                           </p>
                           <p [class.red-color]="!expandedElement?.nationalityOK">
                              {{ 'admin.nat' | translate }}:
                              <b>{{
                                 expandedElement?.nationality ? expandedElement?.nationality : ''
                              }}</b>
                           </p>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile>
                        <div>
                           <p *ngIf="expandedElement?.taxNumber">
                              {{ 'admin.tax' | translate }}:
                              <b>{{
                                 expandedElement?.taxNumber ? expandedElement?.taxNumber : ''
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.socialSecurityNumber">
                              {{ 'admin.ssn' | translate }}:
                              <b>{{
                                 expandedElement?.socialSecurityNumber
                                    ? expandedElement?.socialSecurityNumber
                                    : ''
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.idNumber">
                              {{ 'admin.id' | translate }}:
                              <b>{{
                                 expandedElement?.idNumber ? expandedElement?.idNumber : ''
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.studentIdNumber">
                              {{ 'admin.stud-num' | translate }}:
                              <b>{{
                                 expandedElement?.studentIdNumber
                                    ? expandedElement?.studentIdNumber
                                    : ''
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.studentIdType">
                              {{ 'admin.stud-type' | translate }}:
                              <b>{{
                                 expandedElement?.studentIdType
                                    ? ('' + expandedElement?.studentIdType | translate)
                                    : ''
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.studentStatus">
                              {{ 'admin.stud-stat' | translate }}:
                              <b>{{
                                 expandedElement?.studentStatus
                                    ? ('' + expandedElement?.studentStatus | translate)
                                    : ''
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.area">
                              {{ 'admin.area' | translate }}:
                              <b>{{ expandedElement?.area?.area_name }}</b>
                           </p>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile>
                        <div>
                           <p [class.red-color]="expandedElement?.bankAccounts?.length === 0">
                              {{ 'admin.bankName' | translate }}:
                              <b>{{
                                 expandedElement?.bankAccounts?.[0]
                                    ? expandedElement?.bankAccounts?.[0]?.bankName
                                    : ''
                              }}</b>
                           </p>
                           <p [class.red-color]="expandedElement?.bankAccounts?.length === 0">
                              {{ 'admin.bank' | translate }}:
                              <b>{{
                                 expandedElement?.bankAccounts?.[0]
                                    ? expandedElement?.bankAccounts?.[0]?.accountNumber
                                    : ''
                              }}</b>
                           </p>
                           <p>
                              {{ 'admin.hasShealthCard' | translate }}:
                              <b>{{
                                 expandedElement?.healthCardValidDate === null
                                    ? ('admin.noHas' | translate)
                                    : ('admin.has' | translate)
                              }}</b>
                           </p>
                           <p *ngIf="expandedElement?.healthCardValidDate !== null">
                              {{ 'admin.healthCardValidDate' | translate }}:
                              <b>{{ displayBod('' + expandedElement?.healthCardValidDate) }}</b>
                           </p>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile *ngFor="let address of expandedElement?.addresses">
                        <div>
                           <p *ngIf="address.addressType">
                              {{ 'admin.add-type' | translate }}:
                              <b>{{
                                 address.addressType ? ('' + address.addressType | translate) : ''
                              }}</b>
                           </p>
                           <p *ngIf="address.country" [class.red-color]="!address.countryOK">
                              {{ 'admin.country' | translate }}:
                              <b>{{ address.country ? address.country : '' }}</b>
                           </p>
                           <p *ngIf="address.zipCode">
                              {{ 'admin.zip' | translate }}:
                              <b>{{ address.zipCode ? address.zipCode : '' }}</b>
                           </p>
                           <p *ngIf="address.city" [class.red-color]="!address.cityOK">
                              {{ 'admin.city' | translate }}:
                              <b>{{ address.city ? address.city : '' }}</b>
                           </p>
                           <p *ngIf="address.street" [class.red-color]="!address.streetTypeOK">
                              {{ 'admin.street' | translate }}:
                              <b>{{
                                 address.street ? address.street + ' ' + address.streetType : ''
                              }}</b>
                           </p>
                           <p *ngIf="address.number">
                              {{ 'admin.number' | translate }}:
                              <b>
                                 {{ address.number ? address.number : '' }}
                                 {{ address.staircase ? address.staircase : '' }}
                                 {{ address.floor ? address.floor : '' }}
                                 {{ address.door ? address.door : '' }}
                              </b>
                           </p>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile *ngIf="expandedElement?.study">
                        <div>
                           <p
                              *ngIf="expandedElement?.study.institution"
                              [class.red-color]="!expandedElement?.study.institutionOK"
                           >
                              {{ 'admin.inst' | translate }}:
                              <b>
                                 {{
                                    expandedElement?.study ? expandedElement?.study.institution : ''
                                 }}
                              </b>
                           </p>
                           <p
                              *ngIf="expandedElement?.study.faculty"
                              [class.red-color]="!expandedElement?.study.facultyOK"
                           >
                              {{ 'admin.faculty' | translate }}:
                              <b>
                                 {{ expandedElement?.study ? expandedElement?.study.faculty : '' }}
                              </b>
                           </p>
                           <p
                              *ngIf="expandedElement?.study.course"
                              [class.red-color]="!expandedElement?.study.courseOK"
                           >
                              {{ 'admin.course' | translate }}:
                              <b>
                                 {{ expandedElement?.study ? expandedElement?.study.course : '' }}
                              </b>
                           </p>
                           <p *ngIf="expandedElement?.study.grade">
                              {{ 'admin.grade' | translate }}:
                              <b>
                                 {{
                                    expandedElement?.study
                                       ? convertGrade(expandedElement?.study.grade)
                                       : ''
                                 }}
                              </b>
                           </p>
                           <p *ngIf="expandedElement?.study.graduationDate">
                              {{ 'admin.grad' | translate }}:
                              <b>
                                 {{
                                    expandedElement?.study
                                       ? expandedElement?.study.graduationDate
                                       : ''
                                 }}
                              </b>
                           </p>
                           <p *ngIf="expandedElement?.study.others">
                              {{ 'admin.other' | translate }}:
                              <b>
                                 {{ expandedElement?.study ? expandedElement?.study.others : '' }}
                              </b>
                           </p>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile
                        class="display-column"
                        *ngIf="
                           (expandedElement &&
                              expandedElement.answersKnow &&
                              expandedElement.answersKnow.length > 0) ||
                           (expandedElement &&
                              expandedElement.answersOwn &&
                              expandedElement.answersOwn.length > 0)
                        "
                     >
                        <div>
                           <span
                              *ngIf="
                                 expandedElement &&
                                 expandedElement.answersKnow &&
                                 expandedElement.answersKnow.length > 0
                              "
                           >
                              {{ 'admin.others.known' | translate }}:
                           </span>
                           <br />
                           <ng-container
                              *ngFor="let answer of expandedElement.answersKnow; last as last"
                           >
                              <b *ngIf="last; else notLastKnown">{{ answer.value }}</b>
                              <ng-template #notLastKnown
                                 ><b>{{ answer.value }}, </b></ng-template
                              >
                           </ng-container>
                           <br /><br />
                           <span
                              *ngIf="
                                 expandedElement &&
                                 expandedElement.answersOwn &&
                                 expandedElement.answersOwn.length > 0
                              "
                           >
                              {{ 'admin.others.own' | translate }}:
                           </span>
                           <br />
                           <ng-container
                              *ngFor="let answer of expandedElement.answersOwn; last as last"
                           >
                              <b *ngIf="last; else notLastKnown">{{ answer.value }}</b>
                              <ng-template #notLastKnown
                                 ><b>{{ answer.value }}, </b></ng-template
                              >
                           </ng-container>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile *ngIf="expandedElement?.study">
                        <div>
                           {{ 'admin.lang' | translate }}:
                           <span
                              *ngFor="let lang of expandedElement?.study.languages; let i = index"
                           >
                              <p
                                 [class.red-color]="
                                    expandedElement?.study.languagesOK[i] === 'false'
                                 "
                              >
                                 <b>
                                    {{ lang }}
                                 </b>
                              </p>
                           </span>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile
                        *ngIf="
                           hasResource('person.payment-link') &&
                           (currentFilter === 'need' ||
                              currentFilter === 'trash' ||
                              currentFilter === 'wait') &&
                           expandedElement &&
                           (expandedElement.paymentStatus === 'NEW_NOT_PAYED' ||
                              expandedElement.paymentStatus === 'OLD_NOT_PAYED')
                        "
                     >
                        <div>
                           <b>{{ 'admin.payLink' | translate }}</b>
                           <button mat-mini-fab color="secondary" (click)="onPaymentLink()">
                              <mat-icon>content_copy</mat-icon>
                           </button>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile>
                        <div>
                           <button
                              color="secondary"
                              mat-raised-button
                              (click)="onMissingDataClicked()"
                           >
                              {{ 'admin.missingData' | translate }}
                           </button>
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile
                        *ngIf="
                           expandedElement?.status === 'SYNCED' && hasResource('person.download')
                        "
                     >
                        <div>
                           <b>{{ 'admin.downloadData' | translate }}</b>
                           <button mat-mini-fab color="secondary" (click)="onDownload()">
                              <mat-icon>content_copy</mat-icon>
                           </button>
                        </div>
                     </mat-grid-tile>
                  </mat-grid-list>
               </div>
            </div>
            <div
               class="example-element-detail"
               style="display: block"
               [@detailExpand]="
                  expandedElement !== null && expandedElement?.uuid === element.uuid
                     ? 'expanded'
                     : 'collapsed'
               "
               align="center"
            >
               <div
                  class="example-element-description"
                  style="width: 100%"
                  *ngIf="hasResource('person.update-admin')"
               >
                  <mat-grid-list cols="2" rowHeight="5.5rem" style="width: 100%">
                     <ng-container
                        [formGroup]="adminForm"
                        *ngIf="
                           expandedElement !== null &&
                           expandedElement?.uuid === element.uuid &&
                           (currentFilter === 'rev' ||
                              currentFilter === 'need' ||
                              currentFilter === 'trash')
                        "
                     >
                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'admin.area' | translate
                              }}</mat-label>
                              <input
                                 matInput
                                 [matAutocomplete]="area"
                                 formControlName="area"
                                 #areaInput="matInput"
                                 (keyup)="onAreaDeselect()"
                              />
                              <mat-progress-spinner
                                 *ngIf="areasLoadingSpinner$ | async"
                                 matSuffix
                                 [diameter]="20"
                                 mode="indeterminate"
                              ></mat-progress-spinner>
                              <mat-autocomplete
                                 autoActiveFirstOption
                                 #area="matAutocomplete"
                                 [displayWith]="displayAreaName"
                                 (optionSelected)="onAreaSelect()"
                              >
                                 <mat-option *ngFor="let area of areas$ | async" [value]="area">
                                    {{ area.area_name }}
                                 </mat-option>
                              </mat-autocomplete>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label>{{ 'studies.end-date' | translate }}</mat-label>
                              <select matNativeControl required formControlName="graduationDate">
                                 <option
                                    [value]="graduation"
                                    *ngFor="let graduation of graduations"
                                 >
                                    {{ graduation }}
                                 </option>
                              </select>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'admin.valid-date' | translate
                              }}</mat-label>
                              <input
                                 matInput
                                 [matDatepicker]="picker"
                                 formControlName="membershipValidDate"
                                 (change)="membershipValidDateChange($event)"
                              />
                              <mat-datepicker-toggle
                                 matSuffix
                                 [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker [touchUi]="touchUi$ | async" #picker></mat-datepicker>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label>{{ 'data.status' | translate }}</mat-label>
                              <select matNativeControl formControlName="studentStatus">
                                 <option value="ACTIVE">{{ 'data.active' | translate }}</option>
                                 <option value="PASSIVE">{{ 'data.passive' | translate }}</option>
                              </select>
                           </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile
                           *ngIf="
                              hasResource('person.sync') &&
                              (currentFilter === 'rev' ||
                                 currentFilter === 'need' ||
                                 currentFilter === 'trash')
                           "
                        >
                           <button
                              mat-flat-button
                              color="primary"
                              (click)="onSubmit(false)"
                              [disabled]="adminForm.invalid || !areaSelected"
                           >
                              {{ 'admin.submit' | translate }}
                           </button>
                        </mat-grid-tile>
                        <mat-grid-tile
                           *ngIf="
                              hasResource('person.sync-cash') &&
                              (currentFilter === 'need' || currentFilter === 'trash')
                           "
                        >
                           <button
                              mat-flat-button
                              color="primary"
                              (click)="onSubmit(true)"
                              [disabled]="adminForm.invalid || !areaSelected"
                           >
                              {{ 'admin.cash' | translate }}
                           </button>
                        </mat-grid-tile>
                     </ng-container>
                     <mat-grid-tile
                        *ngIf="hasResource('person.change-status') && currentFilter === 'wait'"
                     >
                        <button mat-flat-button color="warn" (click)="onStatus()">
                           {{ 'admin.correction' | translate }}
                        </button>
                     </mat-grid-tile>
                     <mat-grid-tile
                        *ngIf="hasResource('person.change-status') && currentFilter === 'new'"
                     >
                        <button mat-flat-button color="secondary" (click)="onCheck()">
                           {{ 'admin.checked' | translate }}
                        </button>
                     </mat-grid-tile>
                     <mat-grid-tile
                        *ngIf="
                           hasResource('person.trash') &&
                           (currentFilter === 'rev' || currentFilter === 'need')
                        "
                     >
                        <button mat-flat-button color="warn" (click)="onTrash()">
                           {{ 'admin.delete' | translate }}
                        </button>
                     </mat-grid-tile>
                  </mat-grid-list>
               </div>
            </div>
            <app-uploads
               *ngIf="expandedElement !== null && expandedElement?.uuid === element.uuid"
               [uuid]="'' + expandedElement?.uuid"
               [noOfCols]="2"
               [orientation]="'horizontal'"
               [admin]="true"
               [download]="hasResource('person.download')"
               [documents]="expandedElement?.documents"
               (refresh)="onRefresh()"
            ></app-uploads>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
         mat-row
         [class.red]="!isOk(element)"
         *matRowDef="let element; columns: displayedColumns"
         class="example-element-row"
         style="height: 2rem !important"
         [class.example-expanded-row]="expandedElement === element"
         (click)="onExpand(element.uuid)"
      >
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      <tr class="mat-row" *matNoDataRow>
         <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            {{ 'admin.no-data' | translate }}
         </td>
      </tr>
   </table>
   <mat-paginator
      [ngClass]="currentFilter !== 'synced' ? 'hide' : ''"
      [pageSizeOptions]="[50]"
      showFirstLastButtons
   >
   </mat-paginator>
</div>
