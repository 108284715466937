import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { SupportedLanguages } from './types/language.type';
import { TranslateService } from '@ngx-translate/core';

export function removeTrailingSpaceFromObjectProps(params: any) {
   if (typeof params == 'object' && params) {
      Object.keys(params).map(k => {
         if (typeof params[k] == 'string') {
            params[k] = _.trimEnd(params[k]);
         }

         if (typeof params[k] == 'object') {
            removeTrailingSpaceFromObjectProps(params[k]);
         }
      });
   }
}

export function findAndSetAutosuggestValue(
   list: any[],
   value: string,
   baseArray: boolean,
   form: FormGroup,
   formControlName: string,
   key?: string,
): void {
   if (baseArray) {
      let found = list.find(item => item.toLowerCase() === value.toLowerCase());
      if (found && found.toLowerCase() === value.toLowerCase() && found !== value) {
         form.get(formControlName)?.setValue(found);
      }
   } else {
      if (!key) {
         return;
      }
      let found = list.find(item => item[key].toLowerCase() === value.toLowerCase());
      if (found && found[key].toLowerCase() === value.toLowerCase() && found[key] !== value) {
         form.get(formControlName)?.setValue(found[key]);
      }
   }
   return;
}

export function getGraduations() {
   let yearFrom = +new Date().getFullYear().toString().substring(2) - 1;
   const semesters = [];
   const afterFirstSemester = new Date().getMonth() > 6;
   let currSem = afterFirstSemester ? 2 : 1;
   for (let i = 0; i < 33; i++) {
      semesters.push(`${yearFrom}/${yearFrom + 1}/${currSem}`);

      if (afterFirstSemester) {
         if (i % 2 == 0) yearFrom++;
      } else {
         if (i % 2 == 1) yearFrom++;
      }
      //change semester
      if (currSem == 1) currSem = 2;
      else currSem = 1;
   }
   return semesters;
}

export function depoLangToSupportedLang(
   depoLang: 'HU' | 'EN' | undefined,
): SupportedLanguages | undefined {
   switch (depoLang) {
      case 'EN':
         return 'en';
      case 'HU':
         return 'hu';
      default:
         return undefined;
   }
}

export function supportedLangToDepoLang(
   supportedLang: SupportedLanguages | undefined,
): 'HU' | 'EN' | undefined {
   switch (supportedLang) {
      case 'en':
         return 'EN';
      case 'hu':
         return 'HU';
      default:
         return undefined;
   }
}

export function tranlateToLanguage(
   translateService: TranslateService,
   key: string,
   lang: SupportedLanguages,
): string {
   return translateService.getParsedResult(translateService.translations[lang], key);
}
