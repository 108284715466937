export const routes = {
   person: '/api/person',
   upload: 'api/person/upload',
   documents: 'api/person/documents',
   city: '/api/city',
   zipcode: '/api/city/zip-code',
   country: '/api/country',
   streetType: '/api/street-type',
   nationality: '/api/nationality',
   address: '/api/address',
   school: 'api/school',
   faculty: 'api/faculty',
   course: 'api/course',
   languageSkill: 'api/language-skill',
   studies: 'api/studies',
   questions: 'api/questions',
   questionAnswer: 'api/question-answer',
   entryForm: 'api/entry-form',
   startPayment: 'api/payment/start-payment',
   finishPayment: 'api/payment/finish-payment',
   auth: 'api/auth',
   area: 'api/area',
   bankAccount: 'api/bank-account',
   registered: 'api/person/registered',
   paymentLink: 'api/person/payment-link',
   emailLog: 'api/email-log',
};
