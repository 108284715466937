import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class LanguageService {
   private readonly url: string;

   constructor(private httpClient: HttpClient) {
      this.url = routes.person;
   }

   changeLanguage(lang: string, uuid: string): Observable<any> {
      return this.httpClient.patch<any>(this.url + '/change-language', {
         uuid: uuid,
         language: lang,
      });
   }
}
