<!-- fix mozilla background-attachment "bug" https://css-tricks.com/the-fixed-background-attachment-hack/ -->
<div class="bg" [ngClass]="{ 'bg-red': isTest }"></div>
<div id="test-system" *ngIf="isTest">TESZT RENDSZER!!</div>

<div class="content">
   <button id="change-lang" mat-raised-button (click)="toggleLang()">{{
      currentLang === 'hu' ? 'English' : 'Magyar'
   }}</button>
   <div
      style="position: absolute; left: 10vw; top: calc(2vh + 36px); transform: translateY(-50%)"
      *ngIf="isLoggedIn()"
   >
      <button mat-raised-button color="primary" (click)="onAdmin()">{{
         currentLang === 'hu' ? 'Admin' : 'Admin'
      }}</button>
      <button mat-raised-button color="warn" (click)="onLogout()" style="margin-left: 1vw">{{
         currentLang === 'hu' ? 'Kijelentkezés' : 'Logout'
      }}</button>
      <button
         mat-raised-button
         color="secondary"
         (click)="onEmailAudit()"
         style="margin-left: 1vw"
         >{{ 'Email log' }}</button
      >
   </div>
   <router-outlet></router-outlet>
</div>
<div id="cancel-btn" *ngIf="showClear">
   <button mat-fab color="warn" (click)="onReset()">
      <mat-icon>clear</mat-icon>
   </button>
</div>
