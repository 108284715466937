import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EmailLogFilterDto } from './dto/email-log-filter.dto';
import { EmailType } from './dto/email-type.enum';
import { EmailBodyDialogComponent } from './email-body-dialog/email-body.dialog';
import { EmailLogService } from './email-log.service';
import { EmailLog } from './emailLog.model';

@Component({
   selector: 'app-email-log',
   templateUrl: './email-log.component.html',
   styleUrls: ['./email-log.component.css'],
})
export class EmailLogComponent implements OnInit {
   @ViewChild(MatPaginator) paginator!: MatPaginator;

   displayedColumns: string[] = [
      'sender',
      'recipientName',
      'recipient',
      'type',
      'createdAt',
      'body',
   ];

   filter: EmailLogFilterDto = { page: 0, limit: 50 };

   dataSource = new MatTableDataSource<EmailLog>();

   filterForm = new FormGroup({
      recipient: new FormControl(''),
      recipientName: new FormControl(''),
      sender: new FormControl(''),
      type: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(''),
   });

   logTypes = Object.keys(EmailType);

   constructor(
      private emailLogService: EmailLogService,
      private dialog: MatDialog,
   ) {}

   ngOnInit() {
      this.emailLogService.getList(this.filter).subscribe(data => {
         this.dataSource.data = data;
         this.dataSource.data.forEach((element: EmailLog) => {
            element.createdAt = new Date(element.createdAt).toLocaleString();
         });
         this.dataSource.paginator = this.paginator;
      });
   }

   showBody(emailBody: string) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      const dialogRef = this.dialog.open(EmailBodyDialogComponent, dialogConfig);
      dialogRef.componentInstance.data = {
         body: emailBody,
      };
   }

   onSearch() {
      this.filter = { ...this.filter, ...this.filterForm.value };
      this.emailLogService.getList(this.filter).subscribe(data => {
         this.dataSource.data = data;
         this.dataSource.data.forEach((element: EmailLog) => {
            element.createdAt = new Date(element.createdAt).toLocaleString();
         });
         this.dataSource.paginator = this.paginator;
      });
   }
}
