import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '../config/routes.config';
import { Observable } from 'rxjs';
import { EmailLog } from './emailLog.model';
import { EmailLogFilterDto } from './dto/email-log-filter.dto';

@Injectable({
   providedIn: 'root',
})
export class EmailLogService {
   constructor(private httpClient: HttpClient) {}

   getList(filter: EmailLogFilterDto): Observable<EmailLog[]> {
      return this.httpClient.post<EmailLog[]>(routes.emailLog + '/list', filter);
   }
}
