<mat-card class="example-card shadow" style="margin: 15vh 10vw">
   <mat-card-content>
      <div id="logo-wrapper">
         <img id="logo" src="../../assets/img/logo-600.png" alt="" />
      </div>
      <h2 style="color: var(--dark-purple); text-align: center"
         ><b [innerHTML]="'home.join' | translate"></b
      ></h2>
      <b>{{ 'home.find-job' | translate }}</b>
      <br /><br />
      <b
         >{{ 'home.fill-form' | translate }}
         <span style="color: var(--magenta)">{{ 'home.sign-up' | translate }}</span
         >{{ 'home.quickly' | translate }}</b
      >
      <img src="../../assets/img/beiratkozas.png" alt="" id="img" />
      <br />
      <button id="sign-up" mat-flat-button color="secondary" (click)="onNext()">{{
         'home.i-sign-up' | translate
      }}</button>
   </mat-card-content>
</mat-card>
