import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadConfimationFialogComponent } from '../../shared/upload-confirmation/upload-confirmation.component';
import { UploadsService } from './uploads.service';

@Component({
   selector: 'app-uploads',
   templateUrl: './uploads.component.html',
   styleUrls: ['./uploads.component.css'],
})
export class UploadsComponent implements OnDestroy, OnInit {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() admin!: boolean;

   @Input() download?: boolean;

   @Input() noOfCols!: number;

   @Input() documents!: { uuid: string; fileName: string }[];

   @Input() orientation!: StepperOrientation;

   @Input() completed!: BehaviorSubject<boolean>;

   @Output() refresh = new EventEmitter();

   uploadSpinner$ = new Subject<boolean>();

   form = new FormGroup({
      file: new FormControl(),
      type: new FormControl('', Validators.required),
   });

   file?: File;

   displayedColumns: string[] = ['docType', 'fileName'];

   fileTooLarge = false;

   popupShown = false;

   constructor(
      private service: UploadsService,
      private dialog: MatDialog,
   ) {}

   ngOnInit(): void {
      if (this.admin && this.download) {
         this.displayedColumns.push('download');
      }
      if (!this.admin && !localStorage.getItem('jwt')) {
         this.displayedColumns.push('delete');
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onBack() {
      if (this.stepper) {
         this.stepper.previous();
      }
   }

   onNext() {
      if (!this.popupShown) {
         this.popupShown = true;
         const dialogConfig = new MatDialogConfig();

         dialogConfig.disableClose = true;
         dialogConfig.autoFocus = true;

         const dialogRef = this.dialog.open(UploadConfimationFialogComponent, dialogConfig);
         dialogRef.afterClosed().subscribe(data => {
            if (data) {
               this.completed.next(true);
               setTimeout(() => {
                  this.completed.next(false);
                  this.stepper.next();
               });
            }
         });
      } else {
         this.completed.next(true);
         setTimeout(() => {
            this.completed.next(false);
            this.stepper.next();
         });
      }
   }

   onFileSelected() {
      const inputNode: any = document.querySelector('#file');
      this.fileTooLarge = false;
      if (typeof FileReader !== 'undefined') {
         const reader = new FileReader();

         reader.readAsArrayBuffer(inputNode.files[0]);

         if (inputNode.files[0].size < 10 * 1024 * 1024) {
            this.file = inputNode.files[0];
         } else {
            this.fileTooLarge = true;
         }
      }
   }

   onSubmit() {
      if (this.file) {
         this.uploadSpinner$.next(true);
         this.service
            .upload(this.uuid, this.file, this.form.get('type')?.value)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
               () => {
                  this.file = undefined;
                  this.form.reset();
                  this.refresh.emit();
                  this.uploadSpinner$.next(false);
               },
               () => {
                  this.uploadSpinner$.next(false);
               },
            );
      }
   }

   onDownload(uuid: string, filename: string) {
      this.service
         .downloadDoc(uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(r => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(r);
            a.href = objectUrl;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(objectUrl);
         });
   }

   onDelete(uuid: string) {
      this.service
         .deleteDoc(uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            this.refresh.emit();
         });
   }
}
