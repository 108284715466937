import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth.guard';
import { ContractComponent } from './contract/contract.component';
import { CountrysideComponent } from './countryside/countryside.component';
import { EmailLogComponent } from './email-log/email-log.component';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { LoginComponent } from './login/login.component';
import { PaymentFinishComponent } from './payment-finish/payment-finish.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
   { path: '', component: HomeComponent },
   { path: 'info', component: InfoComponent },
   { path: 'register', component: RegisterComponent },
   { path: 'countryside', component: CountrysideComponent },
   { path: 'login', component: LoginComponent },
   { path: 'payment/finish-payment', component: PaymentFinishComponent },
   { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
   { path: 'contract', component: ContractComponent },
   { path: 'email-log', component: EmailLogComponent },
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
