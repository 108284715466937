import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
   currentLang = 'hu';

   constructor(private router: Router, public translate: TranslateService) {}

   ngOnInit() {
      this.currentLang = this.translate.currentLang;
   }

   onNext() {
      this.router.navigate(['info']);
   }
}
