import { animate, state, style, transition, trigger } from '@angular/animations';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { formatDate } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, forkJoin, iif, of, pipe } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PersonModel } from '../models/person.model';
import { OthersService } from '../modules/others/others.service';
import { PaymentService } from '../modules/payment/payment.service';
import { depoLangToSupportedLang, getGraduations } from '../shared/helper-functions';
import { distinctUntilChangedAndNotify } from '../shared/operators/distinct-until-changed-and-notify';
import { MediaService } from '../shared/services/media.service';
import { AdminService } from './admin.service';
import { MissingDataDialogComponent } from './missing-data-dialog/missing-data-dialog.component';

type Question = {
   id: number;
   /** 1 -- know, 2 -- own */
   column: 1 | 2;
   value: string;
   type: 'BOOLEAN' | 'TEXT';
};

type Answer = {
   question?: string;
   value: string;
};

type FilterStatus = 'wait' | 'new' | 'rev' | 'need' | 'synced' | 'trash';

@Component({
   selector: 'app-admin',
   templateUrl: './admin.component.html',
   styleUrls: ['./admin.component.css'],
   animations: [
      trigger('detailExpand', [
         state('collapsed', style({ height: '0px', minHeight: '0' })),
         state('expanded', style({ height: '*' })),
         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
   ],
})
export class AdminComponent implements OnInit, OnDestroy, AfterViewInit {
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   destroyed$ = new Subject<void>();

   displayedColumns: string[] = [
      'uuid',
      'lastname',
      'firstname',
      'dateOfBirth',
      'taxNumber',
      'email',
      'createdAt',
   ];

   expandedElement!: (PersonModel & { answersKnow?: Answer[]; answersOwn?: Answer[] }) | null;

   wait: PersonModel[] = [];

   new: PersonModel[] = [];

   rev: PersonModel[] = [];

   need: PersonModel[] = [];

   synced: PersonModel[] = [];

   trash: PersonModel[] = [];

   dataSource = new MatTableDataSource(
      this.wait || this.new || this.rev || this.need || this.synced || this.trash,
   );

   @ViewChild(MatSort) sort!: MatSort;

   render = true;

   areaSelected = false;

   adminForm: FormGroup;

   areasLoadingSpinner$: BehaviorSubject<boolean>;

   areas$: Observable<any[]>;

   graduations = getGraduations();

   touchUi$ = new BehaviorSubject(false);

   filterForm = new FormGroup({
      filter: new FormControl(),
   });

   currentFilter: FilterStatus = 'wait';

   waitColor = 'secondary';

   newColor = 'secondary';

   revColor = 'secondary';

   needColor = 'secondary';

   syncedColor = 'secondary';

   trashColor = 'secondary';

   /** Az others komponensnél feltett kérdések
    * - értesz-e a következőkhöz (column 1)
    * - rendelkezel-e a következőkkel (column 2)
    *
    * Mivel ez vélhetőleg nem változik, ezért
    * csak egyszer kérjük le a backendtől, ezek
    * alapján állítjuk elő, a válaszokat a lenyitható
    * táblázat sorokban.
    */
   questions: Question[] = [];

   missingDataDialogRef: MatDialogRef<MissingDataDialogComponent> | null = null;

   @ViewChild(MatPaginator) paginator!: MatPaginator;

   constructor(
      private service: AdminService,
      private _liveAnnouncer: LiveAnnouncer,
      private router: Router,
      private mediaService: MediaService,
      private othersService: OthersService,
      private paymentService: PaymentService,
      private route: ActivatedRoute,
      private missingDataDialog: MatDialog,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
   ) {
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map(screen => {
               return (
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait'
               );
            }),
         )
         .subscribe(this.touchUi$);

      this.adminForm = new FormGroup({
         membershipValidDate: new FormControl('', Validators.required),
         studentStatus: new FormControl('', Validators.required),
         graduationDate: new FormControl('', Validators.required),
         area: new FormControl('', Validators.required),
      });
      const formControls = this.adminForm.controls;

      this.areasLoadingSpinner$ = new BehaviorSubject<boolean>(false);

      this.areas$ = formControls.area.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.areasLoadingSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.areasLoadingSpinner$, false),
         switchMap(name => iif(() => name && name.length > 1, this.service.getArea(name), of([]))),
         map(areas => [...new Set(areas.map((c: { city_name: string; id: number }) => c))]),
         startSpinner(this.areasLoadingSpinner$, false),
      );
   }

   ngOnInit(): void {
      this.service
         .getList()
         .pipe(takeUntil(this.destroyed$))
         .subscribe(r => {
            r.forEach((row: PersonModel) => {
               if (row.name) {
                  row.firstName = row.name.firstName;
                  row.surname = row.name.surname;
               }
               if (
                  row.status === 'WAITING_FOR_PAYMENT' ||
                  row.status === 'WAITING_FOR_IPN' ||
                  row.status === 'REGISTERED'
               ) {
                  this.wait.push(row);
               } else if (row.status === 'NEW_APPLICANT') {
                  this.new.push(row);
               } else if (row.status === 'REVISED_WAITING_FOR_SYNC') {
                  this.rev.push(row);
               } else if (row.status === 'NEEDS_CORRECTION') {
                  this.need.push(row);
               } else if (row.status === 'SYNCED') {
                  this.synced.push(row);
               } else if (row.status === 'TRASH') {
                  this.trash.push(row);
               }
            });
            this.route.queryParamMap.subscribe(p => {
               if (p.has('box')) {
                  this.onFilterStatus(('' + p.get('box')) as FilterStatus);
               } else {
                  this.onFilterStatus('wait');
               }
            });
         });
      this.othersService
         .getQuestions()
         .pipe(takeUntil(this.destroyed$))
         .subscribe(res => {
            this.questions = res;
         });
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   ngAfterViewInit() {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
   }

   applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
   }

   /** Announce the change in sort state for assistive technology. */
   announceSortChange(sortState: Sort | any) {
      // This example uses English messages. If your application supports
      // multiple language, you would internationalize these strings.
      // Furthermore, you can customize the message to add additional
      // details about the values being sorted.
      if (sortState.direction) {
         this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
         this._liveAnnouncer.announce('Sorting cleared');
      }
   }

   convertDate(date: string | Date): string {
      const d = new Date(date);
      return (
         d.getFullYear() +
         '-' +
         (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
         '-' +
         (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
      );
   }

   convertDateAndTime(date: Date | string): string {
      const newDate = new Date(date);
      return formatDate(newDate, 'YYYY-MM-dd HH:mm:ss', 'en-US');
   }

   onNavigate(uuid: string) {
      this.router.navigate(['register'], {
         queryParams: {
            uuid: uuid,
         },
      });
   }

   showTooltip(tooltip: MatTooltip) {
      tooltip.show();
      setTimeout(() => {
         tooltip.hide();
      }, 1000);
   }

   onExpand(uuid: string) {
      this.adminForm.reset();
      if (this.expandedElement?.uuid === uuid) {
         this.expandedElement = null;
      } else {
         this.render = false;
         const person$ = this.service.getOne(uuid).pipe(takeUntil(this.destroyed$));
         const answers$ = this.othersService.getAnswers(uuid).pipe(takeUntil(this.destroyed$));

         forkJoin({ person: person$, answers: answers$ }).subscribe(({ person, answers }) => {
            this.expandedElement = person as PersonModel;
            this.expandedElement.answersKnow = [];
            this.expandedElement.answersOwn = [];

            for (const q of this.questions) {
               const ans = answers[answers.findIndex(r => r.question === q.value)];

               if (!ans || !ans.answer || ans.answer === 'false') {
                  continue;
               }

               if (q.column === 1) {
                  if (q.type === 'BOOLEAN') {
                     this.expandedElement.answersKnow.push({
                        value: q.value,
                     });
                  } else {
                     this.expandedElement.answersKnow.push({
                        question: q.value,
                        value: ans.answer,
                     });
                  }
               } else {
                  if (q.type === 'BOOLEAN') {
                     this.expandedElement.answersOwn.push({
                        value: q.value,
                     });
                  } else {
                     this.expandedElement.answersOwn.push({
                        question: q.value,
                        value: ans.answer,
                     });
                  }
               }
            }

            this.render = true;
            if (this.expandedElement && this.expandedElement.area) {
               this.adminForm.get('area')?.setValue(this.expandedElement.area, {
                  emitEvent: false,
               });
               this.areaSelected = true;
            }
            if (this.expandedElement && this.expandedElement.study) {
               this.adminForm
                  .get('graduationDate')
                  ?.setValue(this.expandedElement.study.graduationDate);
            }
            if (this.expandedElement && this.expandedElement.studentStatus) {
               this.adminForm.get('studentStatus')?.setValue(this.expandedElement.studentStatus);
            }
            if (this.expandedElement && this.expandedElement.membershipValidDate) {
               this.adminForm
                  .get('membershipValidDate')
                  ?.setValue(new Date(this.expandedElement.membershipValidDate));
            }
         });
      }
   }

   displayAreaName(option: { id: number; area_name: string }) {
      return option ? option.area_name : '';
   }

   combinePhoneNumber(phone: any): string {
      return phone.country + '-' + phone.district + '-' + phone.number;
   }

   onSubmit(cashSync: boolean) {
      const { area = undefined, ...formValue } = this.adminForm.value;
      const membershipValidDate = new Date(formValue.membershipValidDate);
      this.service
         .submitAdmin({
            ...formValue,
            membershipValidDate: new Date(
               membershipValidDate.valueOf() - membershipValidDate.getTimezoneOffset() * 60 * 1000,
            ).toISOString(),
            areaId: area?.id,
            uuid: this.expandedElement?.uuid,
         })
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            this.service
               .sync('' + this.expandedElement?.uuid, cashSync)
               .pipe(takeUntil(this.destroyed$))
               .subscribe(() => {
                  if (this.currentFilter === 'new') {
                     const i = this.new.findIndex(r => r.uuid === this.expandedElement?.uuid);
                     const temp = this.new[i];
                     temp.status = 'NEEDS_CORRECTION';
                     Object.assign(temp, { syncDate: new Date() });
                     this.new.splice(i, 1);
                     this.synced.splice(0, 0, temp);
                     this.dataSource = new MatTableDataSource(this.new);
                  } else if (this.currentFilter === 'rev') {
                     const i = this.rev.findIndex(r => r.uuid === this.expandedElement?.uuid);
                     const temp = this.rev[i];
                     temp.status = 'NEEDS_CORRECTION';
                     Object.assign(temp, { syncDate: new Date() });
                     this.rev.splice(i, 1);
                     this.synced.splice(0, 0, temp);
                     this.dataSource = new MatTableDataSource(this.rev);
                  } else if (this.currentFilter === 'wait') {
                     const i = this.wait.findIndex(r => r.uuid === this.expandedElement?.uuid);
                     const temp = this.wait[i];
                     temp.status = 'NEEDS_CORRECTION';
                     Object.assign(temp, { syncDate: new Date() });
                     this.wait.splice(i, 1);
                     this.synced.splice(0, 0, temp);
                     this.dataSource = new MatTableDataSource(this.wait);
                  } else {
                     const i = this.need.findIndex(r => r.uuid === this.expandedElement?.uuid);
                     const temp = this.need[i];
                     temp.status = 'REVISED_WAITING_FOR_SYNC';
                     Object.assign(temp, { syncDate: new Date() });
                     this.need.splice(i, 1);
                     this.synced.splice(0, 0, temp);
                     this.dataSource = new MatTableDataSource(this.need);
                  }
                  this.expandedElement = null;
               });
         });
   }

   onStatus() {
      this.service
         .submitStatus({
            status: this.currentFilter === 'wait' ? 'NEEDS_CORRECTION' : 'REVISED_WAITING_FOR_SYNC',
            uuid: this.expandedElement?.uuid,
         })
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            if (this.expandedElement) {
               if (this.currentFilter === 'new') {
                  const i = this.new.findIndex(r => r.uuid === this.expandedElement?.uuid);
                  const temp = this.new[i];
                  temp.status = 'REVISED_WAITING_FOR_SYNC';
                  this.new.splice(i, 1);
                  this.rev.splice(0, 0, temp);
                  this.dataSource = new MatTableDataSource(this.new);
               } else if (this.currentFilter === 'wait') {
                  const i = this.wait.findIndex(r => r.uuid === this.expandedElement?.uuid);
                  const temp = this.wait[i];
                  temp.status = 'NEEDS_CORRECTION';
                  this.wait.splice(i, 1);
                  this.need.splice(0, 0, temp);
                  this.dataSource = new MatTableDataSource(this.wait);
               }
               this.expandedElement = null;
            }
         });
   }

   onCheck() {
      this.service
         .submitStatus({
            status: 'REVISED_WAITING_FOR_SYNC',
            uuid: this.expandedElement?.uuid,
         })
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            if (this.expandedElement) {
               if (this.currentFilter === 'new') {
                  const i = this.new.findIndex(r => r.uuid === this.expandedElement?.uuid);
                  const temp = this.new[i];
                  temp.status = 'REVISED_WAITING_FOR_SYNC';
                  this.new.splice(i, 1);
                  this.rev.splice(0, 0, temp);
                  this.dataSource = new MatTableDataSource(this.new);
               } else if (this.currentFilter === 'wait') {
                  const i = this.wait.findIndex(r => r.uuid === this.expandedElement?.uuid);
                  const temp = this.wait[i];
                  temp.status = 'REVISED_WAITING_FOR_SYNC';
                  this.wait.splice(i, 1);
                  this.rev.splice(0, 0, temp);
                  this.dataSource = new MatTableDataSource(this.wait);
               } else {
                  const i = this.need.findIndex(r => r.uuid === this.expandedElement?.uuid);
                  const temp = this.need[i];
                  temp.status = 'REVISED_WAITING_FOR_SYNC';
                  this.need.splice(i, 1);
                  this.rev.splice(0, 0, temp);
                  this.dataSource = new MatTableDataSource(this.need);
               }
               this.expandedElement = null;
            }
         });
   }

   onTrash() {
      if (!this.expandedElement) return;

      const updateDataSources = (field: PersonModel[]) => {
         const i = field.findIndex(r => r.uuid === this.expandedElement?.uuid);
         if (i === -1) return;
         const temp = field[i];
         temp.status = 'TRASH';
         field.splice(i, 1);
         this.trash.splice(0, 0, temp);
         this.dataSource = new MatTableDataSource(field);
      };

      this.service
         .putInTrash(this.expandedElement.uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            if (this.expandedElement) {
               if (this.currentFilter === 'new') {
                  updateDataSources(this.new);
               } else if (this.currentFilter === 'rev') {
                  updateDataSources(this.rev);
               } else if (this.currentFilter === 'wait') {
                  updateDataSources(this.wait);
               } else if (this.currentFilter === 'need') {
                  updateDataSources(this.need);
               }
               this.expandedElement = null;
            }
         });
   }

   onFilterStatus(stat: FilterStatus) {
      if (this.currentFilter === 'synced') {
         this.displayedColumns.pop();
      }
      this.expandedElement = null;
      this.currentFilter = stat;
      this.waitColor = 'secondary';
      this.newColor = 'secondary';
      this.revColor = 'secondary';
      this.needColor = 'secondary';
      this.syncedColor = 'secondary';
      this.trashColor = 'secondary';
      this.filterForm.reset();
      if (stat === 'wait') {
         this.dataSource = new MatTableDataSource(this.wait);
         this.waitColor = 'accent';
      } else if (stat === 'new') {
         this.dataSource = new MatTableDataSource(this.new);
         this.newColor = 'accent';
      } else if (stat === 'rev') {
         this.dataSource = new MatTableDataSource(this.rev);
         this.revColor = 'accent';
      } else if (stat === 'need') {
         this.dataSource = new MatTableDataSource(this.need);
         this.needColor = 'accent';
      } else if (stat === 'synced') {
         this.dataSource = new MatTableDataSource(this.synced);
         this.syncedColor = 'accent';
         this.dataSource.paginator = this.paginator;
         this.displayedColumns.push('syncDate');
      } else if (stat === 'trash') {
         this.dataSource = new MatTableDataSource(this.trash);
         this.trashColor = 'accent';
      }
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: {
            box: stat,
         },
         queryParamsHandling: 'merge',
      });
   }

   onRefresh() {
      const uuid = '' + this.expandedElement?.uuid;
      this.expandedElement = null;
      this.onExpand(uuid);
   }

   displayBod(bod: string) {
      try {
         return new Date(bod).toISOString().slice(0, 10);
      } catch (_) {
         return '';
      }
   }

   onDownload() {
      this.service
         .generateDoc('' + this.expandedElement?.uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(r => {
            this.service
               .downloadDoc(r.uuid)
               .pipe(takeUntil(this.destroyed$))
               .subscribe(r => {
                  const a = document.createElement('a');
                  const objectUrl = URL.createObjectURL(r);
                  a.href = objectUrl;
                  a.download =
                     this.expandedElement?.name.surname +
                     '-' +
                     this.expandedElement?.name.firstName +
                     '-data.zip';
                  a.click();
                  URL.revokeObjectURL(objectUrl);
               });
         });
   }

   onPaymentLink() {
      this.service
         .sendPaymentLink(window.location.host, this.expandedElement?.uuid || '')
         .subscribe({
            next: () => {
               this.snackBar.open(
                  this.translate.instant('admin.missingDataDialog.emailSent'),
                  'Ok',
                  {
                     horizontalPosition: this.horizontalPosition,
                     verticalPosition: this.verticalPosition,
                     duration: 3000,
                  },
               );
               this.expandedElement = null;
            },
            error: () => {
               this.snackBar.open(
                  this.translate.instant('admin.missingDataDialog.emailError'),
                  'Ok',
                  {
                     horizontalPosition: this.horizontalPosition,
                     verticalPosition: this.verticalPosition,
                     duration: 3000,
                  },
               );
            },
         });
   }

   isOk(element: any): boolean {
      let ok = element.placeOfBirthOK && element.nationalityOK;
      if (element.study) {
         ok =
            ok && element.study.institutionOK && element.study.facultyOK && element.study.courseOK;
         if (element.study.languagesOK) {
            element.study.languagesOK.forEach((l: any) => {
               ok = ok && l == 'true';
            });
         }
      }
      element.addresses.forEach((e: any) => {
         ok = ok && e.cityOK && e.countryOK && e.streetTypeOK;
      });
      return ok;
   }

   hasResource(resource: string): boolean {
      const resourcesString: string = '' + localStorage.getItem('resources');
      const resources = resourcesString.split(',');
      return resources.findIndex((r: any) => r === resource) > -1;
   }

   membershipValidDateChange($event: any) {
      const result = this.checkStringIsDate($event);
      if (result) {
         this.adminForm.controls.membershipValidDate.setValue(result);
      }
   }

   checkStringIsDate($event: any): Date | undefined {
      if ($event.srcElement && $event.srcElement.value && $event.srcElement.value.length >= 6) {
         let year: number;
         let month: number;
         let day: number;
         const input: string = $event.srcElement.value;
         if (
            input.includes('-') ||
            input.includes('.') ||
            input.includes('/') ||
            input.includes('\\')
         )
            return;
         try {
            year = Number(input.substring(0, 4));
            if (input.length === 8) {
               month = Number(input.substring(4, 6)) - 1;
               day = Number(input.substring(6));
            } else {
               month = Number(input.substring(4, 5)) - 1;
               day = Number(input.substring(5));
            }
            return new Date(year, month, day);
         } catch (e) {
            return;
         }
      }
      return;
   }

   convertGrade(grade: string): string {
      return grade.toString().replace('grade', '');
   }

   onAreaSelect() {
      this.areaSelected = true;
   }

   onAreaDeselect() {
      this.areaSelected = false;
   }

   onMissingDataClicked() {
      this.missingDataDialogRef = this.missingDataDialog.open(MissingDataDialogComponent, {
         data: {
            personId: this.expandedElement?.id,
            language: depoLangToSupportedLang(this.expandedElement?.language),
         },
      });
   }
}

function startSpinner(subject: Subject<boolean>, ends = true) {
   return pipe(
      tap<any>(
         value => {
            if (value) {
               subject.next(ends);
            }
         },
         () => {
            subject.next(false);
         },
         () => {
            subject.next(false);
         },
      ),
   );
}
